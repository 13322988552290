.pubContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    padding: 2rem 2rem 3.5rem 2rem;
}

.cardContainer {
    background-color: white;
    width: 300px;
    margin: 1rem 1rem;
    border-radius: 5px;
}

.cardImg {
    background-color: #fff493;
}

.cardImg img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
}

.cardBody {
    padding: 1rem 1rem 2rem;
}

.cardTitle {
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 700;
    margin: 10px 0;
    color: #424242;
}

.badge {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-right: 10px;
    padding: 2px 15px;
    border-radius: 5px;
}

.cardDesc {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.cardBtn {
    color: #0184ff;
    background-color: transparent;
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    transition: 300ms;
}

.cardBtn:hover {
    color: var(--text-secondary);
}

@media only screen and (max-width: 600px) {
    .pubContainer {
        display: block;
        width: 100%;
    }
    
    .cardContainer {
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .cardContainer {
        width: 48%;
        height: auto;
        margin: 1rem auto;
    }

}