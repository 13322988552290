.homeContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding-bottom: 5rem
}

.primary {
    width: 50%;
}

.titleName, .titleJob {
    color: white;
    font-family: 'Roboto', sans-serif;
    margin: 0 6rem;
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.titleJob {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 6rem;
}

.infoText {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    color: #c7c7c7;
    margin: 2rem 6rem;
    letter-spacing: 0.15em;
}

.infoText2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(173, 212, 212);
    margin: 1rem 6rem;
    letter-spacing: 0.2em;
}

.coloured {
    color: var(--text-secondary);
    font-weight: 400;
}

.secondary {
    width: 50%;
}

.avatarImg {
    width: 55%;
    margin: auto;
}

.avatarImg img {
    border: 1.25rem outset rgb(158, 85, 85);
    border-radius: 50%;
}

.primaryBtn {
    color: var(--text-primary);
    background-color: transparent;
    border: 3px solid var(--text-secondary);
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding: 10px 20px;
    margin: 2rem 6rem;
    width: 35%;
    transition: 300ms;
}

.primaryBtn:hover {
    background-color: rgb(163, 72, 59);
    color: black;
}

@media only screen and (max-width: 600px) {
    .homeContainer {
        display: block;
        overflow-y: scroll;
    }
    .primary {
        width: 100%;
        padding: 20px 15px;
    }
    .secondary {
        width: 100%;
    }
    .titleName, .titleJob {
        margin: 1rem;
        font-size: 2rem;
        letter-spacing: 0.05em;
    }
    .titleJob {
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .infoText {
        font-size: 0.9rem;
        margin: 1rem;
    }
    .infoText2 {
        font-size: 1.0rem;
        margin: 1rem;
    }
    .primaryBtn {
        display: none;
    }
}