.homeContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.primary {
    width: 50%;
}

.titleName, .titleJob {
    color: white;
    font-family: 'PT Sans Caption', sans-serif;
    margin: 0 6rem;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.titleJob {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 6rem;
}

.infoText {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    color: #c7c7c7;
    margin: 2rem 6rem;
    letter-spacing: 0.15em;
}

.coloured {
    color: var(--text-secondary);
    font-weight: 400;
}

.secondary {
    width: 50%;
}

.avatarImg {
    width: 45%;
    margin: auto;
}

.avatarImg img {
    border: 0.5rem solid var(--text-secondary);
    border-radius: 50%;
}

.primaryBtn {
    color: var(--text-primary);
    background-color: transparent;
    border: 2px solid var(--text-secondary);
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding: 10px 20px;
    margin: 2rem 6rem;
    width: 35%;
    transition: 300ms;
}

.primaryBtn:hover {
    background-color: var(--text-secondary);
    color: black;
}

@media only screen and (max-width: 600px) {
    .homeContainer {
        display: block;
        overflow-y: scroll;
        position: fixed;
    }
    
    .primary {
        width: 100%;
        padding: 20px 15px;
    }

    .secondary {
        width: 100%;
        padding-top: 1.5em;
    }

    .titleName, .titleJob {
        margin: 1rem;
        font-size: 1.5rem;
        letter-spacing: 0.05em;
    }
    
    .titleJob {
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    
    .infoText {
        font-size: 0.9rem;
        margin: 1rem;
    }

    .primaryBtn {
        display: none;
    }
  }

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .homeContainer {
        display: block;
        overflow-y: scroll;
    }
    
    .primary {
        width: 100%;
        padding: 40px 15px;
        text-align: center;
    }

    .secondary {
        width: 100%;
        padding: 40px 15px;
    }

    .titleName, .titleJob {
        margin: 1rem;
        font-size: 6rem;
        letter-spacing: 0.05em;
    }
    
    .titleJob {
        margin: 1rem;
        font-size: 4rem;
        font-weight: 700;
    }
    
    .infoText {
        font-size: 2rem;
        margin: 1rem;
    }

    .primaryBtn {
        display: none;
    }

}