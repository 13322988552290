.navbar {
    background-color: var(--bg-primary);
    height: 100vh;
}

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.nav-item {
    width: 100%;
}



.nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    color: var(--text-primary);
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: var(--transition-speed);
}

.nav-link:hover {
    filter: grayscale(0%) opacity(1);
    background: var(--bg-secondary);
    color: var(--text-secondary);
}

.link-text {
    display: none;
    margin-left: 1rem;
}

.nav-link svg {
    width: 2rem;
    min-width: 2rem;
    margin: 0 1rem;
    stroke-width: 1.5;
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}

.logo svg {
    transform: rotate(0deg);
    transition: var(--transition-speed);
}

.logo-text
{
    display: inline;
    position: absolute;
    left: -999px;
    transition: var(--transition-speed);
}

.nav-item:hover svg {
    stroke: var(--text-secondary);
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .navbar {
        bottom: 0;
        width: 100vw;
        height: 5rem;
        position: fixed;
        z-index: 10;
    }

    .logo {
        display: none;
    }

    .navbar-nav {
        flex-direction: row;
    }

    .nav-link {
        justify-content: center;
    }

    main {
        margin: 0;
    }
}


/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .navbar {
        bottom: 0;
        width: 100vw;
        height: 5rem;
        position: fixed;
        z-index: 10;
    }
    
    .logo {
        display: none;
    }
    
    .navbar-nav {
        flex-direction: row;
    }
    
    .nav-link {
        justify-content: center;
    }
    
    main {
        margin: 0;
    }
}