@import url(https://fonts.googleapis.com/css2?family=Muli:wght@300;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1e1e1e !important;
  overflow: hidden;
}

code {
  font-family: Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 16px;
  --text-primary: #ffffff;
  --text-secondary: #E35757;
  --text-title: #08FDC2;
  --bg-primary: #1a1f27;
  --bg-secondary: #0f1014;
  --transition-speed: 300ms;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.contentBox {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.contentBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0f1014;
  -webkit-box-shadow: inset 0 0 6px var(--bg-secondary);
  background-color: transparent;
}

.contentBox::-webkit-scrollbar {
  position: relative;
  width: 10px;
  background-color: #1a1f27;
  background-color: var(--bg-primary);
}

.contentBox::-webkit-scrollbar-thumb {
  background-color: #0f1014;
  background-color: var(--bg-secondary);
}

.contentBox img {
  width: 100%;
  margin: auto;
  display: block;
  align-items: center;
}

.tsparticles-canvas-el {
  height: 100vh !important;
}

a, a:hover {
	text-decoration: none;
}

.socialbtns, .socialbtns ul, .socialbtns li {
  margin: 0;
  padding: 5px;
}

.socialbtns li {
    margin: 10px 20px;
    font-size: 1.2rem;
    list-style: none outside none;
    display: inline-block;
}

.socialbtns .fa {
	color: rgb(255, 255, 255);
	-moz-border-radius: 22px;
	-webkit-border-radius: 22px;
  -o-border-radius: 22px;
  transition: 100ms;
}

.socialbtns .fa:hover {
	color: #E35757;
	color: var(--text-secondary);
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    flex-direction: column-reverse;
    height: 100vh;
    overflow-y: scroll;
  } 
  .contentBox {
    margin-bottom: 5rem;
  }
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .mainContainer {
      flex-direction: column-reverse;
      height: 100vh;
      overflow-y: scroll;
    }

}

.navbar {
    background-color: var(--bg-primary);
    height: 100vh;
}

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.nav-item {
    width: 100%;
}



.nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    color: var(--text-primary);
    text-decoration: none;
    -webkit-filter: grayscale(100%) opacity(0.7);
            filter: grayscale(100%) opacity(0.7);
    transition: var(--transition-speed);
}

.nav-link:hover {
    -webkit-filter: grayscale(0%) opacity(1);
            filter: grayscale(0%) opacity(1);
    background: var(--bg-secondary);
    color: var(--text-secondary);
}

.link-text {
    display: none;
    margin-left: 1rem;
}

.nav-link svg {
    width: 2rem;
    min-width: 2rem;
    margin: 0 1rem;
    stroke-width: 1.5;
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}

.logo svg {
    transform: rotate(0deg);
    transition: var(--transition-speed);
}

.logo-text
{
    display: inline;
    position: absolute;
    left: -999px;
    transition: var(--transition-speed);
}

.nav-item:hover svg {
    stroke: var(--text-secondary);
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .navbar {
        bottom: 0;
        width: 100vw;
        height: 5rem;
        position: fixed;
        z-index: 10;
    }

    .logo {
        display: none;
    }

    .navbar-nav {
        flex-direction: row;
    }

    .nav-link {
        justify-content: center;
    }

    main {
        margin: 0;
    }
}


/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .navbar {
        bottom: 0;
        width: 100vw;
        height: 5rem;
        position: fixed;
        z-index: 10;
    }
    
    .logo {
        display: none;
    }
    
    .navbar-nav {
        flex-direction: row;
    }
    
    .nav-link {
        justify-content: center;
    }
    
    main {
        margin: 0;
    }
}
.homeContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.primary {
    width: 50%;
}

.titleName, .titleJob {
    color: white;
    font-family: 'PT Sans Caption', sans-serif;
    margin: 0 6rem;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.titleJob {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 6rem;
}

.infoText {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    color: #c7c7c7;
    margin: 2rem 6rem;
    letter-spacing: 0.15em;
}

.coloured {
    color: var(--text-secondary);
    font-weight: 400;
}

.secondary {
    width: 50%;
}

.avatarImg {
    width: 45%;
    margin: auto;
}

.avatarImg img {
    border: 0.5rem solid var(--text-secondary);
    border-radius: 50%;
}

.primaryBtn {
    color: var(--text-primary);
    background-color: transparent;
    border: 2px solid var(--text-secondary);
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding: 10px 20px;
    margin: 2rem 6rem;
    width: 35%;
    transition: 300ms;
}

.primaryBtn:hover {
    background-color: var(--text-secondary);
    color: black;
}

@media only screen and (max-width: 600px) {
    .homeContainer {
        display: block;
        overflow-y: scroll;
        position: fixed;
    }
    
    .primary {
        width: 100%;
        padding: 20px 15px;
    }

    .secondary {
        width: 100%;
        padding-top: 1.5em;
    }

    .titleName, .titleJob {
        margin: 1rem;
        font-size: 1.5rem;
        letter-spacing: 0.05em;
    }
    
    .titleJob {
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    
    .infoText {
        font-size: 0.9rem;
        margin: 1rem;
    }

    .primaryBtn {
        display: none;
    }
  }

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .homeContainer {
        display: block;
        overflow-y: scroll;
    }
    
    .primary {
        width: 100%;
        padding: 40px 15px;
        text-align: center;
    }

    .secondary {
        width: 100%;
        padding: 40px 15px;
    }

    .titleName, .titleJob {
        margin: 1rem;
        font-size: 6rem;
        letter-spacing: 0.05em;
    }
    
    .titleJob {
        margin: 1rem;
        font-size: 4rem;
        font-weight: 700;
    }
    
    .infoText {
        font-size: 2rem;
        margin: 1rem;
    }

    .primaryBtn {
        display: none;
    }

}
.aboutContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.primaryAbt {
    width: 60%;
}

.secondaryAbt {
    width: 40%;
    height: 100vh;
    background-color: #131b25;
}

.titleText {
    color: var(--text-title);
    font-family: 'PT Sans Caption', sans-serif;
    margin: 0 6rem;
    font-size: 4rem;
    font-weight: bolder;
    letter-spacing: 0.02em;
}

.paraText {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    color: white;
    margin: 2rem 6rem;
    letter-spacing: 0.15em;
    line-height: 2rem;
}

/* Skills */
*,
*::after,
*::before {
  box-sizing: border-box;
}

.u-center {
  align-content: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0 5rem;
}

.skills {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skill {
  margin: 0.5em 0;
  position: relative;
  width: 100%;
}

.skill__type {
  color: #fff;
  font-weight: 300;
  font-size: 0.85rem;
  letter-spacing: 0.145em;
  text-transform: uppercase;
}

.skill__outer-bar, .skill__inner-bar {
  height: 0.75em;
}

.skill__outer-bar {
  background: #333;
}

.skill__inner-bar {
  -webkit-animation: fillBar 1s forwards;
          animation: fillBar 1s forwards;
  background: #fff;
  left: 5em;
  transform: scaleX(0);
  transform-origin: 0 0;
}

@-webkit-keyframes fillBar {
  to {
    transform: scaleX(1);
  }
}

@keyframes fillBar {
  to {
    transform: scaleX(1);
  }
}

@media only screen and (max-width: 600px) {
  .aboutContainer {
    display: block;
    overflow-y: scroll;
  }
  .primaryAbt {
    width: 100%;
    padding: 10px 20px;
  }

  .secondaryAbt {
      width: 100%;
      height: auto;
      display: block;
  }

  .titleText {
    margin: auto;
    font-size: 3rem;
    text-align: center;
  }

  .paraText {
      font-size: 1rem;
      margin: 1.5rem auto;
      line-height: 1.3rem;
      text-align: justify;
  }

  .u-center {
    display: block;
    margin: auto;
    padding: 0;
  }

  .skill {
    margin: 1rem auto;
  }

  .skills {
    padding: 0 1.5rem 6rem;
  }
}
.pubContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    padding: 2rem 2rem 3.5rem 2rem;
}

.cardContainer {
    background-color: white;
    width: 300px;
    margin: 1rem 1rem;
    border-radius: 5px;
}

.cardImg {
    background-color: #fff493;
}

.cardImg img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
}

.cardBody {
    padding: 1rem 1rem 2rem;
}

.cardTitle {
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 700;
    margin: 10px 0;
    color: #424242;
}

.badge {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-right: 10px;
    padding: 2px 15px;
    border-radius: 5px;
}

.cardDesc {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.cardBtn {
    color: #0184ff;
    background-color: transparent;
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    transition: 300ms;
}

.cardBtn:hover {
    color: var(--text-secondary);
}

@media only screen and (max-width: 600px) {
    .pubContainer {
        display: block;
        width: 100%;
    }
    
    .cardContainer {
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .cardContainer {
        width: 48%;
        height: auto;
        margin: 1rem auto;
    }

}
.contactCont {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 88vh;
    align-items: center;
}

.primaryContact {
    width: 50%;
}

.secondaryContact {
    width: 50%;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #131b25;
}

.contactContents {
    margin: auto;
    width: 80%;
}

hr {
    margin: 3rem 0 2rem;
}

input {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    width: 100%;
    height: 7vh;
    margin: 5px auto;
    padding: 10px;
    color: aliceblue;
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid rgb(192, 192, 192);
}

textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    width: 100%;
    height: 15vh;
    margin: 5px 0;
    padding: 10px;
    color: aliceblue;
    background-color: transparent;
    border-radius: 2px;
    border: 2px solid rgb(192, 192, 192);
}

.emailBtn {
    color: var(--text-primary);
    background-color: transparent;
    border: 2px solid var(--text-title);
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    height: 7vh !important;
    transition: 300ms;
}

.emailBtn:hover {
    background-color: var(--text-title);
    border: 2px solid var(--text-title);
    color: black;
}

.loadingScreen {
    display: flex;
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: #0f0f0fcc;
}

.loadingScreen img {
    margin: auto;
    width: 10%;
}

@media only screen and (max-width: 600px) {
    .contactCont {
        display: block;
        overflow-y: scroll;
    }

    .primaryContact {
        width: 100%;
        padding: 20px 15px;
    }

    .primaryContact p {
        text-align: center;
    }
    
    .secondaryContact {
        padding: 2rem 0 0 0;
        width: 100%;
        height: auto;
        
    }
    
    .loadingScreen img {
        width: 20%;
    } 

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .contactCont {
        display: block;
        overflow-y: scroll;
        height: auto;
    }

    .primaryContact {
        width: 100%;
        padding: 40px 15px;
    }

    .primaryContact p {
        text-align: center;
    }
    
    .titleText {
        font-size: 5rem;
    }

    .paraText {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .secondaryContact {
        display: block;
        width: 100%;
        padding: 2rem 0;
    }

    .contactContents {
        margin: 0 auto 4rem;
    }

    .socialbtns li {
        font-size: 2rem;
        margin: 10px 25px;
    }

    .loadingScreen img {
        width: 20%;
    } 

}
.homeContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding-bottom: 5rem
}

.primary {
    width: 50%;
}

.titleName, .titleJob {
    color: white;
    font-family: 'Roboto', sans-serif;
    margin: 0 6rem;
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.titleJob {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 6rem;
}

.infoText {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    color: #c7c7c7;
    margin: 2rem 6rem;
    letter-spacing: 0.15em;
}

.infoText2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(173, 212, 212);
    margin: 1rem 6rem;
    letter-spacing: 0.2em;
}

.coloured {
    color: var(--text-secondary);
    font-weight: 400;
}

.secondary {
    width: 50%;
}

.avatarImg {
    width: 55%;
    margin: auto;
}

.avatarImg img {
    border: 1.25rem outset rgb(158, 85, 85);
    border-radius: 50%;
}

.primaryBtn {
    color: var(--text-primary);
    background-color: transparent;
    border: 3px solid var(--text-secondary);
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding: 10px 20px;
    margin: 2rem 6rem;
    width: 35%;
    transition: 300ms;
}

.primaryBtn:hover {
    background-color: rgb(163, 72, 59);
    color: black;
}

@media only screen and (max-width: 600px) {
    .homeContainer {
        display: block;
        overflow-y: scroll;
    }
    .primary {
        width: 100%;
        padding: 20px 15px;
    }
    .secondary {
        width: 100%;
    }
    .titleName, .titleJob {
        margin: 1rem;
        font-size: 2rem;
        letter-spacing: 0.05em;
    }
    .titleJob {
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .infoText {
        font-size: 0.9rem;
        margin: 1rem;
    }
    .infoText2 {
        font-size: 1.0rem;
        margin: 1rem;
    }
    .primaryBtn {
        display: none;
    }
}
.pubContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    padding: 2rem 2rem 3.5rem 2rem;
}

.cardContainer {
    background-color: white;
    width: 300px;
    margin: 1rem 1rem;
    border-radius: 5px;
}

.cardImg {
    background-color: #fff493;
}

.cardImg img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
}

.cardBody {
    padding: 1rem 1rem 2rem;
}

.cardTitle {
    font-family: 'PT Sans', sans-serif;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 700;
    margin: 10px 0;
    color: #424242;
}

.badge {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-right: 10px;
    padding: 2px 15px;
    border-radius: 5px;
}

.cardDesc {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.cardBtn {
    color: #0184ff;
    background-color: transparent;
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    transition: 300ms;
}

.cardBtn:hover {
    color: var(--text-secondary);
}

@media only screen and (max-width: 600px) {
    .pubContainer {
        display: block;
        width: 100%;
    }
    
    .cardContainer {
        width: 100%;
        height: auto;
        margin: 20px auto;
    }

}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .cardContainer {
        width: 48%;
        height: auto;
        margin: 1rem auto;
    }

}
.card-img-top {
    max-width: 280px;
    max-height: 210px;
}

.m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 2rem !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }

.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    overflow: auto;
}

.justify-content-center {
    justify-content: center !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }
    .card-group>.card {
        flex: 1 0;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:first-child .card-img-top, .card-group>.card:first-child .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:first-child .card-img-bottom, .card-group>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:last-child .card-img-top, .card-group>.card:last-child .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:last-child .card-img-bottom, .card-group>.card:last-child .card-footer {
        border-bottom-left-radius: 0;
    }
    .card-group>.card:only-child {
        border-radius: 0.25rem;
    }
    .card-group>.card:only-child .card-img-top, .card-group>.card:only-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .card-group>.card:only-child .card-img-bottom, .card-group>.card:only-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
        border-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-height: 100vh;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover, .btn:focus {
    text-decoration: none;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
