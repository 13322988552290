.aboutContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.primaryAbt {
    width: 60%;
}

.secondaryAbt {
    width: 40%;
    height: 100vh;
    background-color: #131b25;
}

.titleText {
    color: var(--text-title);
    font-family: 'PT Sans Caption', sans-serif;
    margin: 0 6rem;
    font-size: 4rem;
    font-weight: bolder;
    letter-spacing: 0.02em;
}

.paraText {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    color: white;
    margin: 2rem 6rem;
    letter-spacing: 0.15em;
    line-height: 2rem;
}

/* Skills */
*,
*::after,
*::before {
  box-sizing: border-box;
}

.u-center {
  align-content: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0 5rem;
}

.skills {
  align-self: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  width: 100%;
}

.skill {
  margin: 0.5em 0;
  position: relative;
  width: 100%;
}

.skill__type {
  color: #fff;
  font-weight: 300;
  font-size: 0.85rem;
  letter-spacing: 0.145em;
  text-transform: uppercase;
}

.skill__outer-bar, .skill__inner-bar {
  height: 0.75em;
}

.skill__outer-bar {
  background: #333;
}

.skill__inner-bar {
  -webkit-animation: fillBar 1s forwards;
          animation: fillBar 1s forwards;
  background: #fff;
  left: 5em;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}

@-webkit-keyframes fillBar {
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes fillBar {
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@media only screen and (max-width: 600px) {
  .aboutContainer {
    display: block;
    overflow-y: scroll;
  }
  .primaryAbt {
    width: 100%;
    padding: 10px 20px;
  }

  .secondaryAbt {
      width: 100%;
      height: auto;
      display: block;
  }

  .titleText {
    margin: auto;
    font-size: 3rem;
    text-align: center;
  }

  .paraText {
      font-size: 1rem;
      margin: 1.5rem auto;
      line-height: 1.3rem;
      text-align: justify;
  }

  .u-center {
    display: block;
    margin: auto;
    padding: 0;
  }

  .skill {
    margin: 1rem auto;
  }

  .skills {
    padding: 0 1.5rem 6rem;
  }
}